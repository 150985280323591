import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photoDB$: Subject<any> = new Subject<any>();
  private photoDB: any;

  constructor() { 
    this.photoDB$.subscribe(db => {
      this.photoDB = db;
    })
  }

  initializeIndexedDB() {
    const self = this;
    let request = indexedDB.open('bakman', 1);
    
    request.onerror = function(event) {
      console.log('error: ', event);
    }

    request.onsuccess = function(event: any) {
      self.photoDB$.next(event.target.result);
    }

    request.onupgradeneeded = function(event: any) {
      console.log('success: db created');
      
      var objStore = event.target.result.createObjectStore("photos", {keyPath: 'id', autoIncrement : true });
      objStore.createIndex('well', 'well', { unique: false })
      self.photoDB$.next(event.target.result);
    }
  }

  addPhoto(well: string, data: any, size: number) {
    if(this.photoDB) {
      const date = new Date();
      const name = well + '_' + (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
      let transaction = this.photoDB.transaction(['photos'], 'readwrite')
                                    .objectStore('photos')
                                    .add({name: name, data: data, well: well, size: size})

      transaction.onsuccess = function(event) {
        console.log('photo uploaded');
      };
    }
  }

  getPhotoObjectStore() {
    console.log(this.photoDB);
    if(this.photoDB) {
      let objStore = this.photoDB.transaction(['photos'], 'readwrite').objectStore('photos')
      console.log(objStore);
      return objStore;
    }
  }
}
