import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BakmanComponent } from './components/bakman/bakman.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthComponent } from './components/auth/auth.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'bakman'
  },
  { 
    path: 'auth', 
    component: AuthComponent
  },
  {
    path: 'bakman',
    component: BakmanComponent,
    loadChildren: () => import('./components/bakman/bakman.module').then(m => m.BakmanModule),
    //canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
