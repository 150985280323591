import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public user;
  isExpanded = false;
  
  title = 'client';

  constructor(private authService: AuthService) {
    this.authService.getUser$().subscribe(user => {
      this.user = user;
    })
  }

  logout() {
    this.authService.logout();
  }
}
