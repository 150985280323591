import { Component, OnInit } from '@angular/core';
import { PhotoService } from './services/photo.service';

@Component({
  selector: 'app-bakman',
  templateUrl: './bakman.component.html',
  styleUrls: ['./bakman.component.scss']
})
export class BakmanComponent implements OnInit {

  constructor(private photoService: PhotoService) { }

  ngOnInit() {
    this.photoService.initializeIndexedDB();
    localStorage.setItem('photoStorage', '0');
  }

}
